import React, { Fragment } from 'react';
import '../App.css';
import { useEffect, useState, useRef} from 'react';
import Web3 from 'web3';
import { Outlet, Link } from "react-router-dom";
import { erc20ABI } from "../abi/erc20ABI";
import { badgeNFTABI } from "../abi/badgeNFTABI";
import { prestigeABI } from "../abi/prestigeABI";
import { pidABI } from "../abi/pidABI";
import UnityGame from "./unity";
import PIDPanel from "./pid";

export default function Badges() {
  const [account, setAccount] = useState();
  const [isNFTloaded, setNFTloaded] = useState(false);
  const [rfUSTBadgeList, setrfUSTBadgeList] = useState([]);
  const [rfAVAXBadgeList, setrfAVAXBadgeList] = useState([]);
  const [rfJEWELBadgeList, setrfJEWELBadgeList] = useState([]);
  const [rfETHBadgeList, setrfETHBadgeList] = useState([]);
  const [rfDAIBadgeList, setrfDAIBadgeList] = useState([]);
  const [azanList, setAzanList] = useState([]);
  const [prestige, setPrestige] = useState('');
  const [hasStake, setHasStake] = useState('');
  const [hasMetamask, setHasMetamask] = useState(false);
  const rfUSTadd = "0x971803c316d80eE490156aF78e8eb5067EDB73AC"
  const rfAVAXadd = "0x7bA36D67023e3a7E3EC5416cbC6B0B078d2b53C7"
  const rfJEWELadd = "0xFe3E6889ea7a7FE54C45D023a4958b9ee06376cF"
  const rfETHadd = "0xA7095980e739d339C2932c0b81b834fd1B706f4c"
  const rfDAIadd = "0xeBaaA8d4024Bcc87Fd908b210A6895be4EaCF410"
  const rfUSTbadgeadd = "0xf4E558373c0d0FA1bcEA89BA886A679943e07d97"
  const rfAVAXbadgeadd = "0xc606F56f9E28508AA3D5a11232E03Aa66312CBDD"
  const rfJEWELbadgeadd = "0x69d0C88A565D95b0700a642e0869cF5923Af02a1"
  const rfETHbadgeadd = "0x47b9C55f71caCF8787d5EbA1dd815f60c387E041"
  const rfDAIbadgeadd = "0x508Ea0b3Ab23DEA39ef51A84117D0cEf9c2F4Cf1"
  const gen0Azan = "0x99ED9fD40a458BdeD5D27F925B74C05C45087b5d"
  const dead ="0x000000000000000000000000000000000000dEaD"
  const prestigeContract= "0x5d62b532a6315dff08ECEC8aCfb940B4039A1b6C"
  const pidContract = "0x14548eD5a0775179c74456f8b1243317F006F371"

  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: "white",
    fontFamily: "Saira Extra Condensed",
    fontSize: "23px",
    fontWeight: "500",
    position: "relative",
  };

  useEffect(() => {


  async function listenMMAccount() {
        window.ethereum.on("accountsChanged", async function() {
          const web3 = new Web3(window.ethereum);
          var account = await web3.eth.getAccounts();
          setAccount(account);
          updateInfo();
        });
        
        }
    listenMMAccount();
    updateInfo();
  }, []);

  
  async function load() {
    const web3 = new Web3(window.ethereum);
    var account = await web3.eth.getAccounts();
    setAccount(account);
    updateInfo();
  }

  async function updateInfo(){
    const web3 = new Web3(window.ethereum);
    await window.ethereum.send('eth_requestAccounts');
    var account = await web3.eth.getAccounts();
    setAccount(account);
  }


  return (
    <div className="container-fluid header">
      <div className="row navbar">
        <div className="col-lg-3 nopadding">
        <div>
          <a href="/"><h3 className="headerlink">RELIC QUEST</h3></a>
        </div>
      </div>
      <div className="col-lg d-flex justify-content-center">
        <div>
        </div>
      </div>
        <div className="col-lg-3">
          <div>
          {!(account) ?
          (<button id="account" className="btn-style-staking" onClick={load} style={{float: "right", marginRight: "5px", cursor: "pointer", color: "#FFF", padding: "10px", fontSize: "16px"}}>Connect</button> )
          :
          (<p></p>)
        }

          </div>
        </div>
      </div>

      <Outlet />
        <div className="row fadeIn body-wrap-staking">
          <div className="bg3"></div>
          <div className="col">
            <div className="row justify-content-around">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="row">

                        <div className="col-8 stakingpanel">
                          <p class="questtext">Behold, majestic city's gate stands shut, yet my arrival not ordained. Stars foretell a future passage. Await the appointed hour when the barriers yield. Soon, I shall grace these hallowed streets, unlocking the unseen wonders within.</p>
                        </div>

                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="row socialfooter">
      </div>
    </div>
  );
}
