import React, { Fragment } from 'react';
import { useEffect, useState, useRef} from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import Web3 from 'web3';
import { prestigeABI } from "../abi/prestigeABI";

const UnityGame = () => {
  const [prestige, setPrestige] = useState('');
  const prestigeContract= "0x5d62b532a6315dff08ECEC8aCfb940B4039A1b6C"
  const { unityProvider, isLoaded, loadingProgression, unityInstance, sendMessage, addEventListener } = useUnityContext({
    loaderUrl: "play/RelicQuestV2Test/Build/RelicQuestV2Test.loader.js",
    dataUrl: "play/RelicQuestV2Test/Build/RelicQuestV2Test.data.unityweb",
    frameworkUrl: "play/RelicQuestV2Test/Build/RelicQuestV2Test.framework.js.unityweb",
    codeUrl: "play/RelicQuestV2Test/Build/RelicQuestV2Test.wasm.unityweb",
  });


  useEffect(() => {
      
      const intervalId = setInterval(() => {
            if (isLoaded) {
              getPrestige();}
      }, 20000);
    
      }, [addEventListener, unityInstance, unityProvider, isLoaded]);



    async function getPrestige() {
        const web3 = new Web3(window.ethereum);
        var account = await web3.eth.getAccounts();
        const prestigeObj = new web3.eth.Contract(prestigeABI, prestigeContract);
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          if (accounts.length === 0) {
            setPrestige(0);
            return prestige;
          }
          const prestige = await prestigeObj.methods.getPrestige().call({ from: accounts[0] });
          const prestigeStr = prestige.toString();
          setPrestige(prestigeStr);
          sendMessage('Brain', 'OnPrestigeValueReceived', prestigeStr);
          sendMessage('Brain', 'OnAccountValueReceived', accounts[0]);
          // Send the prestige value to the Unity game using postMessage()
          
        } catch (error) {
          console.error(error);
          setPrestige(0);
          return prestige;
        }
      }


  return (
        <Fragment>
          <div className="row" style={{ display: Math.round(loadingProgression * 100) === 100 ? 'none' : 'flex' }}>
            <div className="col d-flex align-items-center justify-content-center" style={{ visibility: Math.round(loadingProgression * 100) === 100 ? 'hidden' : 'visible' }}>
              <p className="whitecolor" style={{ visibility: Math.round(loadingProgression * 100) === 100 ? 'hidden' : 'visible' }}>Loading Game... {Math.round(loadingProgression * 100)}%</p>
            </div>
          </div>
          <div className="row" style={{ display: Math.round(loadingProgression * 100) === 100 ? 'flex' : 'none' }}>
            <div className="col d-flex align-items-center justify-content-center">
              <Unity style={{width: 960, height: 600 }} unityProvider={unityProvider}/>
            </div>
          </div>
          
        </Fragment>
  );
}

export default UnityGame;
