import './App.css';
import { Outlet, Link } from "react-router-dom";


export default function App() {
  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: "white",
    fontFamily: "Saira Extra Condensed",
    fontSize: "23px",
    fontWeight: "500",
    position: "relative",
  };




  return (
    <div className="container-fluid header">
    <div className="row navbar">
      <div className="col-lg-3 nopadding">
        <div>
          <a href="/"><h3 className="headerlink">RELIC QUEST</h3></a>
        </div>
      </div>
      <div className="col-lg d-flex justify-content-center">
      </div>
      <div className="col-lg-3">
      </div>
    </div>
    <Outlet />
      <div className="row fadeIn body-wrap-staking">
        <div className="stars"></div>
        <div className="col">
          <div className="row justify-content-around">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-8 stakingpanel">
                      <p class="inkTitle">A GRAND QUEST AWAITS</p>
                      <p class="questtext">Intrepid Wanderers: Now is the time to choose your fate. A path of light or descent into darkness. Fate awaits your resolute hearts. Stand firm. Decide. Triumph or doom, fate itself is in your hands. In twilight's embrace, destiny beckons. Embrace courage, defy the encroaching night. Choose valor over fear. Unite as one. Seek truth, wield hope. This moment, your legacy.</p>
                       <div className="row">
                          <div className="col-6">
                            <div class="wrapper">
                              <div class="button-wrapper">
                               <Link to="/game" style={linkStyle}><button class="button">
                                RAID DUNGEON
                              </button></Link> {" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div class="wrapper">
                              <div class="button-wrapper">
                               <Link to="/city" style={linkStyle}><button class="citybutton">
                                ENTER CITY
                              </button></Link> {" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row socialfooter">
      </div>
    </div>

  );
}
