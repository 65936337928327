import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import App from "./App";
import Game from "./routes/game";
import City from "./routes/city";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="game" element={<Game />} />
      <Route path ="city" element={<City />} />
      <Route
      path="*"
      element={
        <div className="body-wrap">
          <p>There's nothing here!</p>
        </div>
      }
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);
